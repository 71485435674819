const requestApi = require("@/lib/request");
// 报表接口
module.exports = {
    //销售报表
    saleList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/report/sale-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //会员报表
    vipList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/report/vip-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //销售导出
    saleExcel(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/report/sale-excel', data, true).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //会员导出
    vipExcel(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/report/vip-excel', data, true).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}